@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

.minibtcswap_heading {
  text-align: center;
  height: 30px;
  font-size: 26px;
  font-weight: bolder;
  margin-top: -10px;
  margin-bottom: 25px;
  color: #fff;
}
