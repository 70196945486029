#join-pool-button {
  border-radius: 20px !important;
  background: #05195a !important;
  font-weight: 500 !important;
  height: 30px;
  /* font-size: 12px !important; */
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  padding: 20px 8px !important;
}
