@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
.minibtcswap__logo {
  -o-object-fit: contain;
  object-fit: contain;
  height: 110px;
  margin-top: -25px;
}

@media (max-height: 800px) and (max-width: 415px) {
  .minibtcswap__logo {
    height: 100px;
  }
}

.minibtcswap__logo {
  text-align: center;
  padding: 20px 0px;
}

.minibtcswap__toggleContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: -5px;
  padding-bottom: 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.minibtcswap__toggleContainer p {
  padding: 0px 20px;
  color: #fff;
  font-size: 14px;
  font-weight: bolder;
  margin-bottom: 8px;
}

.toggleActive {
  text-shadow: 0px 0px 20px #04bbfb, 1px 1px 10px #04bbfb;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 58px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 7px;
  bottom: 1px;
  background-color: #04bbfb;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #fff;
}

input:focus + .slider {
  -webkit-box-shadow: 0 0 1px #2196f3;
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*# sourceMappingURL=MiniBtcSwap.css.map */
