.currencyText,
.modal_text {
  color: #05195a !important;
  fill: #05195a !important;
}

#button-tooltip {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 10px;
  padding: 20px;
  font-size: 12px;
  color: #04bbfb;
  border: 1px solid rgba(5, 25, 90, 0.125);
  border-radius: 10px;
  width: 175px;
  z-index: 9999;
}

.helper {
  cursor: help;
  font-size: 16px;
  color: #04bbfb;
  border: none;
  background-color: transparent;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
